import * as React from 'react'
import type { Control, UseFormSetValue } from 'react-hook-form'
import z from 'zod'

import { cn } from '@/lib/utils'
import { experimentSchema } from '@/lib/validations/experiments'
import { Button } from '@/components/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command'
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { priorityColors } from '@/components/icons'

import { Priority, Status } from '../experiment-form'

interface FormComboboxInputProps {
  control: Control<z.infer<typeof experimentSchema>>
  setValue: UseFormSetValue<z.infer<typeof experimentSchema>>
  values: Priority[] | Status[]
  name: 'name' | 'status' | 'priority' | 'description'
  experiment?: Experiment
  colorIcon?: boolean
  selectedValue?: Status
}

export default function FormComboboxInput({
  control,
  values,
  setValue,
  name,
  experiment,
  colorIcon,
  selectedValue,
}: FormComboboxInputProps) {
  const [openPopover, setOpenPopover] = React.useState<boolean>(false)
  const [selectedValues, setSelectedValues] = React.useState<
    Status | Priority | null
  >(selectedValue ? selectedValue : values[0])

  React.useEffect(() => {
    const currentSelected =
      name === 'priority'
        ? values.find((value) => value.value === experiment?.priority)
        : values.find((value) => value.value === experiment?.status)

    if (currentSelected) {
      setSelectedValues(currentSelected)
    }
  }, [experiment, values, name])

  React.useEffect(() => {
    if (selectedValue) {
      setValue(name, selectedValue.value)
    }
  }, [selectedValue, setValue, name])

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className='flex flex-col'>
          <Popover open={openPopover} onOpenChange={setOpenPopover}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  type='button'
                  variant='outline'
                  size='sm'
                  role='combobox'
                  className={cn(
                    'w-[140px] justify-between shadow-sm',
                    !field.value && 'text-muted-foreground'
                  )}
                >
                  {selectedValues ? (
                    <>
                      {colorIcon ? (
                        <div
                          className={cn(
                            priorityColors[selectedValues.value],
                            'size-5 rounded-full p-1'
                          )}
                        >
                          <div className='size-3 rounded-full bg-current' />
                        </div>
                      ) : (
                        <selectedValues.icon className='mr-2 size-4 shrink-0' />
                      )}
                      {selectedValues.label}
                    </>
                  ) : (
                    <>+ Set {name}</>
                  )}
                </Button>
              </FormControl>
            </PopoverTrigger>

            <PopoverContent className='w-[200px] p-0'>
              <Command>
                <CommandInput placeholder={`Change ${name}...`} />
                <CommandEmpty>No {name} found.</CommandEmpty>
                <CommandGroup>
                  {values.map((status) => (
                    <CommandItem
                      value={status.label}
                      key={status.value}
                      onSelect={() => {
                        setSelectedValues(
                          values.find((stat) => stat.value === status.value) ||
                            null
                        )
                        setValue(name, status.value)
                        setOpenPopover(false)
                      }}
                    >
                      {colorIcon ? (
                        <div
                          className={cn(
                            priorityColors[status.value],
                            'mr-2 size-4 rounded-full p-1'
                          )}
                        >
                          <div className='size-2 rounded-full bg-current' />
                        </div>
                      ) : (
                        <status.icon
                          className={cn(
                            'mr-2 size-4',
                            status.value === field.value
                              ? 'opacity-100'
                              : 'opacity-20'
                          )}
                        />
                      )}
                      <span>{status.label}</span>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage className='w-[150px] text-xs' />
        </FormItem>
      )}
    />
  )
}
