import { Icons } from '@/components/icons'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { useState } from 'react'
import { toast } from 'sonner'
import { deleteUserExperiment } from '../actions'
import { usePathname, useRouter } from 'next/navigation'
import { buttonVariants } from '@/components/ui/button'
import { cn } from '@/lib/utils'

interface ExperimentDeleteAlertProps {
  open: boolean
  setOpen: (value: boolean) => void
  experimentId: string
}

export default function ExperimentDeleteAlert({ open, setOpen, experimentId }: ExperimentDeleteAlertProps) {
  const [isDeleteing, setIsDeleteing] = useState<boolean>(false)
  const pathname = usePathname()
  const router = useRouter()


  const handleDeleteExperiment = async () => {
    setIsDeleteing(true)
    try {
      const { error } = await deleteUserExperiment(experimentId)

      if (error) {
        toast.error('Error deleting', {
          description: error
        })
      }
      toast.success('Experiment deleted')
      if (pathname.includes(experimentId)) {
        router.push('/')
      }
    } catch (err) {
      toast.error('Unexpected error', {
        description: 'Please try again.'
      })
    } finally {
      setIsDeleteing(false)
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you want to delete this experiment?
          </AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => handleDeleteExperiment()}
            className={cn(buttonVariants({ variant: 'destructive', size: 'sm' }))}
          >
            {isDeleteing ? (
              <Icons.spinner className='mr-2 size-4 animate-spin' />
            ) : (
              <Icons.trash className='mr-2 size-4' />
            )}
            <span>Delete</span>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
