'use client'

import { useState } from 'react'
import { useRouter } from 'next/navigation'
import { zodResolver } from '@hookform/resolvers/zod'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import {
  ArrowUpCircle,
  BadgeAlert,
  CheckCircle2,
  Circle,
  LucideIcon,
  SignalHigh,
  SignalLow,
  SignalMedium,
  SignalZero,
  XCircle,
} from 'lucide-react'
import { useForm } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import { toast } from 'sonner'
import { z } from 'zod'

import { experimentSchema } from '@/lib/validations/experiments'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { Separator } from '@/components/ui/separator'

import { DialogDescription, DialogTitle } from '../ui/dialog'
import { createUserExperiment, updateUserExperiment } from './actions'
import FormComboboxInput from './components/form-combobox-input'

export const priorities = [
  { label: 'No Priority', value: 'no_priority', icon: SignalZero },
  { label: 'Low', value: 'low', icon: SignalLow },
  { label: 'Medium', value: 'medium', icon: SignalMedium },
  { label: 'High', value: 'high', icon: SignalHigh },
  { label: 'Urgent', value: 'urgent', icon: BadgeAlert },
]

export const statuses = [
  { label: 'Todo', value: 'todo', icon: Circle },
  { label: 'In Progress', value: 'in_progress', icon: ArrowUpCircle },
  { label: 'Completed', value: 'completed', icon: CheckCircle2 },
  { label: 'Canceled', value: 'canceled', icon: XCircle },
]

export type Priority = {
  value: (typeof priorities)[number]['value']
  label: string
  icon: LucideIcon
}

export type Status = {
  label: string
  value: (typeof statuses)[number]['value']
  icon: LucideIcon
}

interface ExperimentFormProps {
  experiment?: Experiment
  closeModal: () => void
  selectedStatus?: Status
}

export default function ExperimentForm({
  experiment,
  closeModal,
  selectedStatus,
}: ExperimentFormProps) {
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const editing = !!experiment?.id
  const router = useRouter()

  const form = useForm<z.infer<typeof experimentSchema>>({
    resolver: zodResolver(experimentSchema),
    defaultValues: {
      description: experiment?.description || '',
      name: experiment?.name || '',
      priority: experiment?.priority || 'no_priority',
      status: experiment?.status || 'todo',
    },
  })

  const {
    register,
    formState: { errors },
  } = form

  async function handleSubmit(values: z.infer<typeof experimentSchema>) {
    if (editing) {
      setIsSaving(true)
      try {
        const { error } = await updateUserExperiment(values, experiment.id)
        if (error) {
          toast.error('Problem creating experiment', {
            description: error,
          })
        } else {
          toast.success('Experiment updated', {
            description: 'Create and edit problem configuration',
          })
          router.refresh()
          closeModal()
        }
      } catch (err) {
        toast.error('There was an error while updating experiment', {
          description: 'Please wait and try again.',
        })
      } finally {
        setIsSaving(false)
      }
    } else {
      setIsSaving(true)
      try {
        const { error, data } = await createUserExperiment(values)
        if (error) {
          toast.error('Problem creating experiment', {
            description: error,
          })
        } else {
          toast.success('Experiment created')
          if (data) {
            router.push(`/experiments/${data[0].id}`)
          }
          closeModal()
        }
      } catch (err) {
        toast.error('There was an error while updating experiment', {
          description: 'Please wait and try again.',
        })
      } finally {
        setIsSaving(false)
      }
    }
  }

  return (
    <>
      <VisuallyHidden.Root>
        <DialogTitle>{editing ? 'Edit' : 'Create'} experiment</DialogTitle>
        <DialogDescription>Edit or create new experiment</DialogDescription>
      </VisuallyHidden.Root>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className='relative'>
          <div className='p-2 sm:px-4'>
            <label htmlFor='name' className='sr-only'>
              Name
            </label>
            <TextareaAutosize
              autoFocus
              id='name'
              itemType='text'
              defaultValue={experiment?.name as string}
              className='block w-full resize-none appearance-none overflow-hidden bg-transparent pt-4 text-2xl font-medium focus:outline-none'
              placeholder='Experiment name'
              {...register('name')}
            />
            {errors.name && (
              <p className='mb-2 text-sm text-destructive'>
                {errors?.name?.message}
              </p>
            )}
            <label className='sr-only' htmlFor='description'>
              Description
            </label>
            <TextareaAutosize
              id='description'
              defaultValue={experiment?.description as string}
              className='block w-full resize-none appearance-none overflow-hidden bg-transparent pb-12 focus:outline-none sm:text-sm sm:leading-6'
              placeholder='Add description...'
              {...register('description')}
            />
            <div className='inset-x-2 flex gap-2'>
              <FormComboboxInput
                name='status'
                values={statuses as Status[]}
                setValue={form.setValue}
                control={form.control}
                experiment={experiment}
                selectedValue={selectedStatus}
              />
              <FormComboboxInput
                name='priority'
                values={priorities as Priority[]}
                setValue={form.setValue}
                control={form.control}
                experiment={experiment}
                colorIcon
              />
            </div>
          </div>
          <Separator />
          <div className='flex items-center justify-end p-2 py-4'>
            <Button
              size='sm'
              type='submit'
              disabled={isSaving}
              isLoading={isSaving}
              className='mx-4 w-full sm:w-fit'
            >
              {experiment ? 'Edit' : 'Create'} experiment
            </Button>
          </div>
        </form>
      </Form>
    </>
  )
}
